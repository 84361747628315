import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
  DELETE_ALL_ALERTS_ACTION,
  DELETE_ONE_ALERT_ACTION,
  GET_ALL_ALERTS_ACTION,
  GET_USER_MESSAGES,
  deleteAllAlerts,
  deleteOneAlert,
  getAlerts,
  getUserMessages,
  hideMessage,
  selectContentTypeInbox,
  showMessage,
} from '../actions';
import { trackEvent } from '../api/analytics';
import { createLoadingSelector } from '../api/selectors';
import { Box, Center, Column, Pressable, Row, Text } from '../commonComponents';
import {
  enableChatsUnread,
  enableLastJobPostedReminders,
  enablePaymentExpirationReminders,
  enableProfileCompletenessReminders,
} from '../growthbook';
import { Colors } from '../themes/colors';
import Alerts from './Alerts';
import { Chat } from './Inbox/Chat/Chat';
import { useChatsUnreadCount } from './Inbox/Chat/hooks/useChatsUnreadCount';
import { useInbox } from './Inbox/hooks/useInbox';
import { useNotificationCount } from './Inbox/Notification/hooks/useNotificationCount';
import { Notification } from './Inbox/Notification/Notification';
import { useTaskDeletion } from './Inbox/Notification/Tasks/hooks/useTaskDeletion';
import { NotificationType } from './Inbox/Notification/Type';
import Messages from './messages/Messages';

const LIMIT_VALUE = 15;

export default ({ history, isRootComponent = true }) => {
  const selectedContent = useSelector((state) => state.messages.selectedContent);
  const conversationCount = useSelector((state) => state.user.user.conversationCount);
  const dashboardInfo = useSelector((state) => state.job.dashboardInfo);

  const isFinalPage = useSelector((state) => state.messages.isFinalPage);
  const isFinalPageAlerts = useSelector((state) => state.alerts.isFinalPage);

  const isActiveRequest = useSelector((state) => state.messages.isActiveRequest);
  const isActiveRequestAlerts = useSelector((state) => state.alerts.isActiveRequest);

  const selectedOfficeId = useSelector((state) => state.user.selectedOfficeId);

  const totalUnread = useChatsUnreadCount();
  const totalNotifications = useNotificationCount();
  const { handleDeleteAll, handleDeleteOne } = useTaskDeletion();

  const isEnableChatUnread = useRef(enableChatsUnread()).current;
  const isEnableLastJobPostedReminders = useRef(enableLastJobPostedReminders()).current;
  const isEnablePaymentExpirationReminders = useRef(enablePaymentExpirationReminders()).current;
  const isEnableProfileCompletenessReminders = useRef(enableProfileCompletenessReminders()).current;

  const isEnableNotificationReminder =
    isEnableLastJobPostedReminders ||
    isEnablePaymentExpirationReminders ||
    isEnableProfileCompletenessReminders;

  const isAlertContent = selectedContent === NotificationType.Alert;
  const isMessageContent = selectedContent === NotificationType.Message;

  const dispatch = useDispatch();

  const loadingSelector = createLoadingSelector([
    GET_ALL_ALERTS_ACTION,
    GET_USER_MESSAGES,
    DELETE_ALL_ALERTS_ACTION,
    DELETE_ONE_ALERT_ACTION,
  ]);

  const { page } = useSelector((store) => store.messages.searchParams);
  const searchParamsAlerts = useSelector((store) => store.alerts.searchParams);

  const isLoading = useSelector((state) => loadingSelector(state));
  const refMessages = useRef(null);
  const refAlerts = useRef(null);

  const handleScrollMessages = () => {
    const messagesScroll = refMessages?.current;

    const userScrollHeight = messagesScroll?.scrollHeight - messagesScroll?.scrollTop;
    const clientHeight = messagesScroll?.clientHeight;

    if (userScrollHeight <= clientHeight && !isActiveRequest) {
      dispatch(getUserMessages({ page: page + 1, limit: LIMIT_VALUE }));
    }
  };

  const handleScrollAlerts = () => {
    const alertsScroll = refAlerts.current;

    const userScrollHeight = alertsScroll?.scrollHeight - alertsScroll?.scrollTop;
    const clientHeight = alertsScroll?.clientHeight + 20;

    if (userScrollHeight <= clientHeight && !isActiveRequestAlerts && !isFinalPageAlerts) {
      dispatch(getAlerts({ page: searchParamsAlerts?.page + 1, limit: LIMIT_VALUE }));
    }
  };

  const { getMessages, getNotifications } = useInbox();

  useEffect(() => {
    if (isAlertContent && !isEnableNotificationReminder) {
      dispatch(getAlerts({ page: 1, limit: LIMIT_VALUE }));
    } else if (isMessageContent && !isEnableChatUnread) {
      dispatch(getUserMessages({ page: 1, limit: LIMIT_VALUE }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOfficeId]);

  const selectInboxType = (type) => {
    if (type === 'alerts') {
      getNotifications();
      dispatch(selectContentTypeInbox({ payload: 'alerts' }));
    } else if (type === 'messages') {
      getMessages();
      dispatch(selectContentTypeInbox({ payload: 'messages' }));
    }
  };

  const deleteAll = () => {
    dispatch(
      showMessage({
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete all your messages?',
        isError: true,
        popupProps: {
          leftButtonText: 'Go Back',
          leftButtonAction: () => dispatch(hideMessage()),
          rightButtonText: 'Remove',
          rightButtonAction: () => {
            dispatch(deleteAllAlerts());
            dispatch(hideMessage());
          },
        },
      }),
    );
  };

  const selectedAlert = (job) => {
    // TODO: - follow new alert schema
    if (job.job_path === 'DNT_OFFER') {
      trackEvent('Office Dashboard - Inbox Notifications Tile Clicked', {
        actionNeededShiftsCount: dashboardInfo?.actionRequired,
        scheduledShiftsCount: dashboardInfo?.scheduled,
        openShiftsCount: dashboardInfo?.activeRequest,
      });

      history.push(`/dashboard/job/${job.joboffer_id}`, {
        jobId: job.joboffer_id,
      });
    }
  };

  const deleteOneAlertSubmit = (alertId) => {
    dispatch(deleteOneAlert({ alertId }));
  };

  const selectedMessage = (job) => {
    trackEvent('Office Dashboard - Inbox Chats Tile Clicked', {
      actionNeededShiftsCount: dashboardInfo?.actionRequired,
      scheduledShiftsCount: dashboardInfo?.scheduled,
      openShiftsCount: dashboardInfo?.activeRequest,
    });

    history.push(`/dashboard/chat/${job.id}`, { jobId: job.id });
  };

  const renderTabUnderline = () => (
    <Box backgroundColor={Colors.primary_500} height={2} marginTop={5} width="100%" />
  );

  const renderContent = () => {
    if (isAlertContent) {
      if (isEnableNotificationReminder) {
        return <Notification />;
      }

      return (
        <Column
          ref={refAlerts}
          onScroll={handleScrollAlerts}
          style={{ overflowY: 'scroll', maxHeight: 500 }}
        >
          <Alerts
            isLoading={isLoading}
            selectedJobs={selectedAlert}
            deleteAll={handleDeleteAll}
            deleteOneAlert={handleDeleteOne}
          />

          {!isFinalPageAlerts && isLoading ? (
            <Center width="100%" height={100}>
              <ClipLoader sizeUnit="px" size={50} color={Colors.primary_500} loading />
            </Center>
          ) : null}
        </Column>
      );
    }

    if (isMessageContent) {
      if (isEnableChatUnread) {
        return <Chat isRootComponent={isRootComponent} />;
      }

      return (
        <Column
          ref={refMessages}
          onScroll={handleScrollMessages}
          style={{ overflowY: 'scroll', maxHeight: 500 }}
        >
          <Messages
            isLoading={isLoading}
            selectedJobs={selectedMessage}
            deleteAll={deleteAll}
            deleteOneAlert={deleteOneAlertSubmit}
          />
          {!isFinalPage && isLoading ? (
            <Center width="100%" height={100}>
              <ClipLoader sizeUnit="px" size={50} color={Colors.primary_500} loading />
            </Center>
          ) : null}
        </Column>
      );
    }

    return null;
  };

  return (
    <Column
      className="inbox"
      style={{
        borderRadius:
          isRootComponent && (isEnableChatUnread || isEnableNotificationReminder) ? 0 : 25,
      }}
    >
      <Column width="100%" margin="15px 25px" flex={1}>
        <Text
          bold
          fontSize={32}
          marginBottom={0}
          marginLeft={30}
          marginRight={20}
          textAlign="center"
        >
          Inbox
        </Text>
        <Row alignItems="baseline" gap={20} width="90%">
          <Box marginLeft={20}>
            {isEnableNotificationReminder ? (
              <Row alignItems="baseline" gap={4}>
                <button
                  style={{
                    background: 'none',
                    color: isAlertContent ? Colors.neutral_600 : Colors.neutral_300,
                    fontSize: 22,
                    fontWeight: isAlertContent ? 'bold' : 'normal',
                    textAlign: 'center',
                    position: 'relative',
                    height: 40,
                  }}
                  type="button"
                  onClick={() => selectInboxType('alerts')}
                >
                  Notifications
                  {isAlertContent && renderTabUnderline()}
                </button>
                <Center
                  backgroundColor={isAlertContent ? Colors.primary_500 : Colors.primary_100}
                  borderRadius={4}
                  height={totalNotifications > 99 ? 28 : 24}
                  width={totalNotifications > 99 ? 28 : 24}
                >
                  <Text
                    bold
                    color={isAlertContent ? Colors.white : Colors.primary_500}
                    fontSize={totalNotifications > 99 ? 12 : 14}
                  >
                    {totalNotifications}
                  </Text>
                </Center>
              </Row>
            ) : (
              <>
                <Pressable onPress={() => selectInboxType('alerts')}>
                  <Text
                    cursor="pointer"
                    fontSize={24}
                    fontWeight={isAlertContent ? 'bold' : 'normal'}
                    marginBottom={isAlertContent ? 0 : 7}
                    marginLeft={20}
                    marginRight={20}
                    textAlign="center"
                  >
                    Notifications
                  </Text>
                </Pressable>
                {isAlertContent ? renderTabUnderline() : null}
              </>
            )}
          </Box>
          <Box>
            {isEnableChatUnread ? (
              <Row alignItems="baseline" gap={4}>
                <button
                  style={{
                    background: 'none',
                    color: isMessageContent ? Colors.neutral_600 : Colors.neutral_300,
                    fontSize: 22,
                    fontWeight: isMessageContent ? 'bold' : 'normal',
                    textAlign: 'center',
                    position: 'relative',
                    height: 40,
                  }}
                  type="button"
                  onClick={() => selectInboxType(NotificationType.Message)}
                >
                  Chats
                  {isMessageContent && renderTabUnderline()}
                </button>
                <Center
                  backgroundColor={isMessageContent ? Colors.primary_500 : Colors.primary_100}
                  borderRadius={4}
                  height={totalUnread > 99 ? 28 : 24}
                  width={totalUnread > 99 ? 28 : 24}
                >
                  <Text
                    bold
                    color={isMessageContent ? Colors.white : Colors.primary_500}
                    fontSize={totalUnread > 99 ? 12 : 14}
                  >
                    {totalUnread}
                  </Text>
                </Center>
              </Row>
            ) : (
              <>
                <Row>
                  <Pressable onPress={() => selectInboxType('messages')}>
                    <Text
                      cursor="pointer"
                      fontSize={24}
                      fontWeight={isMessageContent ? 'bold' : 'normal'}
                      marginBottom={isMessageContent ? 0 : 7}
                      marginLeft={20}
                      marginRight={20}
                    >
                      Chats
                    </Text>
                  </Pressable>
                  {conversationCount ? (
                    <Box
                      backgroundColor="#f4511d"
                      borderRadius={50}
                      width={10}
                      height={10}
                      marginRight={5}
                      marginTop={20}
                    />
                  ) : null}
                </Row>
                {isMessageContent ? (
                  <Box backgroundColor={Colors.primary_500} height={2} marginTop={5} width="100%" />
                ) : null}
              </>
            )}
          </Box>
        </Row>
        <Column flex={1} gap={20} marginBlock={20} width="100%" maxHeight="100%">
          {renderContent()}
        </Column>
      </Column>
    </Column>
  );
};
