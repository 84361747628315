import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearIncidentPopups,
  reportIncident,
  resetIncident
} from '~/actions';
import { trackEvent } from '~/api/analytics';
import { incidentTypes } from '~/components/JobList/TodayJobs/ReportIncidentPopup/utils/incidentPopupUtil';
import useLoading from '../../../../../hooks/useLoading';

export const useReportIncidentPopup = () => {
  const { incident, showReportIncidentPopup, reportIncidentPopupState } = useSelector(
    (state) => state.job,
  );
  const { professionalName, job, incidentType } = incident;

  const dispatch = useDispatch();

  const { isLoading } = useLoading(['REPORT_INCIDENT_ACTION']);

  const submitReportIncident = useCallback(
    (repostMaxTime) => {
      const jobId = job?.id;

      if (!jobId) {
        return;
      }

      const eventName =
        incidentType === incidentTypes.walkedOut
          ? 'Todays Shift - Report Walked Out Submitted'
          : 'Todays Shift - Report No Show Submitted';

      trackEvent(eventName, {
        shiftId: job?.id,
        professionalId: job?.professional?._id,
      });

      dispatch(
        reportIncident({
          jobId,
          incidentType,
          name: professionalName,
          repostMaxTime,
        }),
      );
    },
    [dispatch, incidentType, job?.id, job?.professional?._id, professionalName],
  );

  const closeReportIncident = useCallback(() => {
    dispatch(clearIncidentPopups());
    dispatch(resetIncident());
  }, [dispatch]);

  return {
    isLoading,
    incident,
    incidentType,
    showReportIncidentPopup,
    submitReportIncident,
    closeReportIncident,
    reportIncidentPopupState,
  };
};
