import { BackButton, Box, FloatingContainer, Row, Screen, Text } from '~/commonComponents';
import { ReviewForm } from './components';

export const NPSReview = () => (
  <Screen>
    <FloatingContainer borderRadius={0} paddingBottom={32}>
      <Row
        alignItems="center"
        justifyContent="center"
        marginBottom={32}
        marginTop={44}
        position="relative"
      >
        <Box position="absolute" left={0}>
          {/* @ts-expect-error */}
          <BackButton />
        </Box>
        <Text bold fontSize={32}>
          GoTu Experience
        </Text>
      </Row>
      <ReviewForm />
    </FloatingContainer>
  </Screen>
);
