import { getValue } from 'firebase/remote-config';
import { remoteConfig } from '../api/firebaseConfig';

/**
 * Keys for Firebase Remote Config keys.
 * Can be used when calling {@link remoteConfig}'s `getValue()`
 *
 * @enum {string}
 */
const RemoteConfigKeys = {
  allowedWcStates: 'ALLOWED_WC_STATES',
  stateToDisableReferralProgram: 'stateToDisableReferralProgram',
  offerExpirationStates: 'OFFER_EXPIRATION_STATES',
  unlimitedApplicationEnabledStates: 'UNLIMITED_APPLICATION_ENABLED_STATES',
  statesToDisableTempRequests: 'BLOCKED_STATES',
  autofillMinAttendanceScore: 'AUTOFILL_MIN_ATTENDANCE_SCORE',
  SHOW_WC_TERMS_POPUP: 'SHOW_WC_TERMS_POPUP',
  minimumHighlyRatedProfessionalRating: 'MINIMUM_HIGHLY_RATED_PROFESSIONAL_RATING',
  maxRepostTimeThreshold:'MAX_REPOST_TIME_THRESHOLD',
};

export const RemoteConfigUtils = {
  getReferralUnavailableStates() {
    const stateToDisableReferralProgram = getValue(
      remoteConfig,
      RemoteConfigKeys.stateToDisableReferralProgram,
    )
      ?.asString()
      ?.replace(/\s+/g, '');

    return stateToDisableReferralProgram?.split(',');
  },

  getAllowedWcStates() {
    return getValue(remoteConfig, RemoteConfigKeys.allowedWcStates).asString();
  },

  getOfferExpirationStates() {
    return getValue(remoteConfig, RemoteConfigKeys.offerExpirationStates).asString();
  },

  getUnlimitedApplicationEnabledStates() {
    return getValue(
      remoteConfig,
      RemoteConfigKeys.unlimitedApplicationEnabledStates,
    )
      ?.asString()
      ?.replace(/\s+/g, '').split(',');
  },

  getDisabledTempPostStates() {
    const stateToDisableTempJobPost = getValue(
      remoteConfig,
      RemoteConfigKeys.statesToDisableTempRequests,
    )
      ?.asString()
      ?.replace(/\s+/g, '');

    return stateToDisableTempJobPost?.split(',');
  },

  getAutofillMinAttendanceScore() {
    return getValue(remoteConfig, RemoteConfigKeys.autofillMinAttendanceScore).asString();
  },

  getShowDualModelLaunchStates() {
    const stateToShowDualModelLaunchPopup = getValue(
      remoteConfig,
      RemoteConfigKeys.SHOW_WC_TERMS_POPUP,
    )
      ?.asString()
      ?.replace(/\s+/g, '');
    return stateToShowDualModelLaunchPopup?.split(',');
  },

  getMinimumHighlyRatedProfessionalRating() {
    return getValue(
      remoteConfig,
      RemoteConfigKeys.minimumHighlyRatedProfessionalRating,
    )?.asNumber();
  },

  getMaxRepostTimeThreshold() {
    return getValue(remoteConfig, RemoteConfigKeys.maxRepostTimeThreshold).asNumber();
  },
};
