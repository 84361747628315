import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showConfirmReportIncidentPopup } from '~/actions';
import { RadioOption, Text } from '~/commonComponents';
import { ConfirmPopupContent } from '~/components/shared/ConfirmPopupContent';
import WarningIcon from '~/images/warning-icon.svg';
import { Colors } from '~/themes/colors';
import { incidentTypes } from '../utils/incidentPopupUtil';

export const ConfirmationSelectIncident = ({ onClose }) => {
  const dispatch = useDispatch();
  const [incidentType, setIncidentType] = useState(null);

  return (
    <ConfirmPopupContent
      Icon={() => <img src={WarningIcon} style={{ marginBottom: 20 }} alt="warning_icon" />}
      title="Report An Incident"
      description={
        <div>
          <Text textAlign="center">What type of incident would you like to report?</Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginTop: 20,
              marginBottom: 20
            }}
          >
            <RadioOption
              textStyle={{ color: Colors.neutral_500 }}
              onClick={() => setIncidentType(incidentTypes.noShow)}
              text="No-Show"
              checked={incidentType === incidentTypes.noShow}
            />
            <RadioOption
              textStyle={{ color: Colors.neutral_500 }}
              onClick={() => setIncidentType(incidentTypes.walkedOut)}
              text="Walked-Out"
              checked={incidentType === incidentTypes.walkedOut}
            />
          </div>
          <Text textAlign="center">
            If the incident doesn&apos;t fall into these two categories, please call or text our
            Customer Support at 786-460-2170, and they will be able to assist you.
          </Text>
        </div>
      }
      leftButtonAction={onClose}
      leftButtonText="Go Back"
      rightButtonAction={() => {
        dispatch(showConfirmReportIncidentPopup({ incidentType }));
      }}
      rightButtonDisabled={!incidentType}
      rightButtonText="Continue"
    />
  );
};
