import { ClipLoader } from 'react-spinners';
import history from '../history';
import { Colors } from '../themes/colors';
import { Text } from './Text';

type TProps = {
  color?: string;
  isLoading?: boolean;
  url?: string;
  onClick?: () => void;
};

export const SkipButton = ({
  color = Colors.secondary_500,
  isLoading = false,
  url,
  onClick,
}: TProps) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else if (url) {
      history.push(url);
    } else {
      history.goBack();
    }
  };

  return (
    <button
      className="button-reset"
      style={{
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        width: 'fit-content',
      }}
      type="button"
      onClick={handleOnClick}
    >
      {isLoading ? (
        <ClipLoader sizeUnit="px" size={24} color={color} loading={isLoading} />
      ) : (
        <Text bold color={color} fontSize={18}>
          Skip
        </Text>
      )}
    </button>
  );
};
