import {
  ArrowRightIcon,
  AtIcon,
  BellIcon,
  CameraIcon,
  CircleCheckSolidIcon,
  EmptyChatIcon,
  EmptyNotificationIcon,
  PhoneIcon,
  ThunderLightningIcon,
  WarningOutlineIcon,
  WarningSolidIcon,
} from '../../images/icons';

export const iconRegistry = {
  'arrow-right': ArrowRightIcon,
  bell: BellIcon,
  phone: PhoneIcon,
  at: AtIcon,
  'warning-solid': WarningSolidIcon,
  'warning-outline': WarningOutlineIcon,
  camera: CameraIcon,
  'circle-check-solid': CircleCheckSolidIcon,
  'thunder-lightning': ThunderLightningIcon,
  'empty-notification': EmptyNotificationIcon,
  'empty-chat': EmptyChatIcon,
};
