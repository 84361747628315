import moment from 'moment';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  showReportIncidentPopup
} from '~/actions';
import { getShouldEnableNCNSRepost, getShouldEnableNCNSRepostStates, } from '~/growthbook';
import { RemoteConfigUtils } from '~/utils/RemoteConfigUtils';


export const useReportIncidentItem = (job) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);

  const maxRepostTimeThreshold = RemoteConfigUtils.getMaxRepostTimeThreshold();
  
  const enabledStates = getShouldEnableNCNSRepostStates();
  const isEnabledState = enabledStates.includes(user?.address[0]?.state);;

  // Checks if an incident has already been reported for the job
  const isReported = !!job?.incident;

  // Determines if the job can be reported
  const canReport = moment().diff(moment(job?.shift?.start), 'minutes') >= 30;

  // Determines if the job can be reposted
  const canRepost =
    getShouldEnableNCNSRepost() &&
    isEnabledState &&
    canReport &&
    moment().diff(moment(job?.shift?.start), 'minutes') <= maxRepostTimeThreshold;

  const onReportIncidentClick = useCallback(() => {
    dispatch(
      showReportIncidentPopup({
        job,
        professionalName: job?.professional?.full_name,
        canReport,
        canRepost
      }),
    );
  }, [canReport, canRepost, dispatch, job]);

  return {
    isReported,
    canReport,
    canRepost,
    onReportIncidentClick,
  };
};
