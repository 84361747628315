import moment from 'moment';
import { useState } from 'react';
import { Center, Divider, Text } from '~/commonComponents';
import InputSelect from '~/commonComponents/InputSelect';
import { ConfirmPopupContent } from '~/components/shared/ConfirmPopupContent';
import ConfirmationRepostIcon from '~/images/confirmation-repost-icon.svg';
import { generateHourlyOptions } from '../utils/incidentPopupUtil';

export const ConfirmationToRepost = ({ onSubmit, data }) => {
  const [isRepost, setIsRepost] = useState(false);
  const [repostMaxTime, setRepostMaxTime] = useState(null);
  const [hourlyOptions, setHourlyOptions] = useState([]);

  const isRepostStyle = { opacity: isRepost ? 0.4 : 1 };

  const onRepost = () => {
    setIsRepost(true);
    const nowAt = new Date();
    const maxAt = new Date(data?.job?.shift?.end);

    setHourlyOptions(generateHourlyOptions(nowAt, maxAt));
  };

  const getNewStartTime = () => {
    const nextHour = moment().add(1, 'hours');
    return nextHour.format('h:mm a');
  };

  return (
    <ConfirmPopupContent
      Icon={() => (
        <img
          src={ConfirmationRepostIcon}
          style={{ width: 195, height: 161, marginBottom: 20,...isRepostStyle }}
          alt="icon"
        />
      )}
      title="GoTu Can Help Fill Your Shift!"
      titleStyle={isRepostStyle}
      description={
        <div style={{ marginBottom: -40 }}>
          <Text textAlign="center" style={isRepostStyle} color="neutral_500">
            Would you like GoTu to repost this shift as an urgent shift with auto-confirm and reach
            out to any available professional that is on-call?
          </Text>
          {isRepost && (
            <>
              <Divider marginVertical={40} />
              <div>
                <Text textAlign="center" marginLeft={-20} marginRight={-20} color="neutral_500">
                  We’ll be reposting your shift with a start time of <b>{getNewStartTime()}.</b>
                </Text>

                {!!hourlyOptions.length && (
                  <Center flex={1} columnGap={10} flexDirection="row" marginTop={15}>
                    <Text textAlign="right" width={140} color="secondary_500" bold>
                      Stop Searching At:
                    </Text>
                    <div style={{ width: 160 }}>
                      <InputSelect
                        value={repostMaxTime}
                        setValue={setRepostMaxTime}
                        options={hourlyOptions}
                      />
                    </div>
                  </Center>
                )}
              </div>
            </>
          )}
        </div>
      }
      leftButtonAction={() => {
        if (!isRepost) {
          onSubmit();
          return;
        }
        setRepostMaxTime(null);
        setIsRepost(false);
      }}
      leftButtonText={isRepost ? 'Back' : 'No, Thanks'}
      rightButtonAction={() => {
        if (!isRepost) {
          onRepost();
          return;
        }
        onSubmit(repostMaxTime);
      }}
      rightButtonText={isRepost ? 'Send Alert' : 'Yes, Repost'}
    />
  );
};
