import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SEND_NPS_REVIEW, sendNpsReview } from '~/actions';
import {
  Button,
  Column,
  FloatingContainer,
  RadioOption,
  Row,
  SkipButton,
  Text,
} from '~/commonComponents';
import useLoading from '~/hooks/useLoading';
import { Colors } from '~/themes/colors';

// @ts-expect-error
import LogoDarkIcon from '~/images/logos/logoSmallDark.svg';

export const ReviewForm = () => {
  const [rating, setRating] = useState<number>(0);
  const isLoading = useLoading([SEND_NPS_REVIEW]);
  const btnActionRef = useRef<string>(undefined);

  const dispatch = useDispatch();

  const handleCancel = () => {
    btnActionRef.current = 'CANCEL';
    dispatch(sendNpsReview({ npsScore: -1 }));
  };

  const handleSubmit = () => {
    btnActionRef.current = 'SUBMIT';
    dispatch(
      sendNpsReview({
        npsScore: rating,
      }),
    );
  };

  return (
    <Column backgroundColor={Colors.neutral_50} marginHorizontal={40}>
      <FloatingContainer>
        <img
          src={LogoDarkIcon}
          style={{ width: 74, height: 74, margin: '100px auto 20px' }}
          alt="logo_dark_icon"
        />
        <Text bold color={Colors.secondary_500} fontSize={24} textAlign="center">
          Rate your experience!
        </Text>
        <Text color={Colors.neutral_500} textAlign="center" marginTop={16}>
          Your opinion is important to us.
        </Text>
        <Text bold color={Colors.neutral_600} fontSize={18} marginTop={40} textAlign="center">
          How likely are you to recommend GoTu to a colleague?
        </Text>
        <Column margin="24px auto 100px" width={384} gap={8}>
          <Row justifyContent="space-between">
            <Text color={Colors.neutral_500}>Unlikely</Text>
            <Text color={Colors.neutral_500}>Very likely</Text>
          </Row>
          <Row justifyContent="space-between">
            {Array(10)
              .fill(0)
              .map((_, index) => index + 1)
              .map((item, index) => {
                const isChecked = item === rating;

                return (
                  <RadioOption
                    // eslint-disable-next-line react/no-array-index-key
                    key={`rate-${index}`}
                    checked={isChecked}
                    text={item}
                    textStyle={{
                      color: Colors.neutral_600,
                      margin: 0,
                      marginTop: 4,
                    }}
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                    onClick={() => setRating(item)}
                  />
                );
              })}
          </Row>
        </Column>
      </FloatingContainer>
      <Column alignItems="center" paddingVertical={24} paddingBottom={48} gap={20}>
        {/* @ts-expect-error */}
        <Button
          color="primary"
          disabled={rating === 0}
          isLoading={isLoading && btnActionRef.current === 'SUBMIT'}
          style={{ width: 280 }}
          text="Submit"
          onClick={handleSubmit}
        />
        <SkipButton
          isLoading={isLoading && btnActionRef.current === 'CANCEL'}
          onClick={handleCancel}
        />
      </Column>
    </Column>
  );
};
