import React, { forwardRef, useState } from 'react';
import { motion } from 'framer-motion';

import InfoIcon from '../images/icons/Info';
import { Colors } from '../themes/colors';

const Tooltip = forwardRef(({ content }, ref) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const tooltipVariants = {
    show: { opacity: 1, display: 'flex' },
    hide: { opacity: 0, transitionEnd: { display: 'none' } },
  };

  return (
    <div className="tooltip-wrapper">
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={ref}>
        <InfoIcon color={Colors.neutral_300} />
      </div>

      <motion.div
        className="tooltip"
        initial="hide"
        animate={isTooltipVisible ? 'show' : 'hide'}
        transition={{ duration: 0.3 }}
        variants={tooltipVariants}
      >
        {content}
      </motion.div>
    </div>
  );
});

export default Tooltip;
