import { CSSProperties, forwardRef } from 'react';
import { getResolvedSpacing, getResolvedStyles } from '~/themes/utils';
import { IBoxProps } from './interfaces';

export const Box = forwardRef<HTMLDivElement, IBoxProps>(
  ({ className, children, style, direction = 'column', ...rest }, ref) => {
    const baseStyles: CSSProperties = {
      display: 'flex',
      flexDirection: direction,
      ...getResolvedSpacing(rest),
      ...style,
    };

    const { styles, htmlProps } = getResolvedStyles(baseStyles, rest);

    return (
      <div className={className} style={styles} ref={ref} {...htmlProps}>
        {children}
      </div>
    );
  },
);
